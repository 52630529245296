:root {
  --color-winter: #2a89d1;
  --accent-color: #f5e640;
  --theme-color: var(--color-winter);
  --body-color: #edf6fc;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: var(--body-color);
  background-color: var(--theme-color);
  box-sizing: border-box;
  justify-content: center;
  font: 100% / 1.5 system-ui, sans-serif;
  transition: background-color .75s ease-out 1s;
  display: flex;
}

body.home {
  hyphens: auto;
  text-wrap: pretty;
}

@media screen and (width >= 768px) {
  body.home {
    align-items: center;
  }
}

main {
  max-width: 60ch;
  margin: 2rem;
  font-size: 1.5rem;
}

body.home main {
  font-weight: 700;
}

@media screen and (width >= 768px) {
  body.home main {
    columns: 3 auto;
    column-rule: solid 2px #69a4d1;
    max-width: 70ch;
    margin: 0;
  }
}

article {
  margin: 2rem 0;
}

#links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

#links ul li {
  display: inline;
}

#links ul li:after {
  content: ", ";
}

#links ul li:last-child:after {
  content: "";
}

#links ul li a:before {
  content: "↗";
  color: var(--accent-color);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.5;
}

h1 {
  font-size: 2rem;
}

h2, h3, h4, h5, h6 {
  margin-top: 1.5rem;
}

code, pre {
  font: 1rem / 1.5 monospace, sans-serif;
}

pre {
  background-color: #00000040;
  border-radius: .5rem;
  padding: 1rem;
}

figure {
  text-align: center;
}

.meta {
  font-size: 1.25rem;
}

ol, ul {
  margin: 0;
}

li {
  margin: 0 0 .25rem;
}

a {
  color: inherit;
  transition: color .15s ease-out;
}

a:hover {
  color: var(--accent-color);
}
/*# sourceMappingURL=index.ba01e865.css.map */
