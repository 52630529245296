// Vars

:root {
    // --color-spring: #51943e;
    // --color-summer: #FA7D41;
    // --color-fall: #B763BF;
    --color-winter: #2a89d1;
    --accent-color: #f5e640;
    --theme-color: var(--color-winter);
    --body-color: #edf6fc;
}

// ------
// Layout
// ------

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

body {
    color: var(--body-color);
    font: normal 100%/1.5 system-ui, sans-serif;
    display: flex;
    justify-content: center;
    background-color: var(--theme-color);
    transition: background-color .75s ease-out 1s;
    box-sizing: border-box;

    // Home page only 
    &.home {
        hyphens: auto;
        text-wrap: pretty;   
    }    
}

// Desktop
@media screen and (min-width: 768px) {
    body.home {
        align-items: center;
    }
}

main {
    font-size: 1.5rem;
    margin: 2rem;
    max-width: 60ch;
}

body.home main {
    font-weight: 700;
}

// Desktop
@media screen and (min-width: 768px) {
    body.home main {
        // font-weight: 700;
        max-width: 70ch;
        margin: 0;
        columns: 3 auto;
        column-rule: solid 2px #69a4d1;
    }
}

article {
    margin: 2rem 0;
}


#links ul {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline;
        &:after {
            content: ', ';
        }
        &:last-child:after {
            content: '';
        }
                
        a:before {
            content: "\2197";
            // opacity: .7;
            color: var(--accent-color);
        }
    }
}

// ------
// Headings and copy 
// ------

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0 0 0 0;
    font-size: 1.5rem;
    line-height: 1.5;
}

h1 {
    font-size: 2rem;
}

h2,
h3,
h4,
h5,
h6 {
    margin-top: 1.5rem;
}

code,
pre {
    font: normal 1rem/1.5 monospace, sans-serif;
}

pre {
    padding: 1rem;
    background-color: rgba(0,0,0,0.25);
    border-radius: .5rem;
}

figure {
    text-align: center;
}

.meta {
    font-size: 1.25rem;
}

// .subhead {

// }

// ------
// Lists
// ------

ol,
ul {
    margin: 0;
}

li {
    margin: 0 0 .25rem 0;
}

// ------
// Links 
// ------

a {
    color: inherit;
    transition: color .15s ease-out;
    &:hover {
        color: var(--accent-color);
    }
}
